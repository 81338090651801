<template>
    <div class="modal" tabindex="-1" v-if="ppShow">
        <div class="dimmed" @click="ppShowFn()"></div>
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header fw-500 w-100 border-0 pb-0 justify-content-center cursor-pointer" @click="moveLink()">
                    <p class="text-truncate">{{ title }}</p>
                </div>
                <div class="modal-body fs-px-14 cursor-pointer" @click="moveLink()" v-html="content">
                    
                </div>
                <div class="modal-footer p-0 border-0 overflow-hidden">
                    <div class="flex-between-center m-0 p-3 bg-primary w-100 h-100 fs-px-13 text-white">
                        <div class="round_checkbox d-flex align-items-center">
                            
                        </div>
                        <span class="cursor-pointer fs-px-12 w-fit-content" @click="ppShowFn()">{{ $t("etc.2") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
	export default {
        data(){
            return{
                check1: false,
                ppShow: false,
                code : '',
                title : '',
                content : '',
            }
        },
		mounted(){
        },
        methods:{
            moveLink(){
                this.$router.push({path:'/'+this.$i18n.locale+'/notice/'+this.code}); 
            },
            ppShowFn(){
                if(this.check1){
                    this.AddMemberNoticeNoshow()
                }
                this.ppShow = !this.ppShow;
            },
            AddMemberNoticeNoshow(){
                const code = this.code;
                const body = {code};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/AddMemberNoticeNoshow`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
            }
        },
	}
</script>

<style lang="scss" scoped>

.modal{
  background-color: transparent;
}

.dimmed{
    z-index: -1;
    background-color: rgba(0,0,0,.3);
}
.modal-body{
    max-height: 60vh;
    overflow-y: auto;
}

.theme01 .modal-body,
.theme01 .modal-header{
    color: #fff !important;
}
</style>